import FormErrorController from '../FormErrorController';
export default class ShowErrorsOnSubmit {
    onSubmitFailed() {
        if (FormErrorController.hasErrors()) {
            console.error(FormErrorController.errors);
            FormErrorController.showList();
        }
        else {
            console.error('Submit failed but no errors registered in the FormErrorController.');
        }
    }
}
