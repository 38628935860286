import FormErrorController from './FormErrorController';
import { ManageIban } from './utils/ManageIban';
import { PresetValidityMessage } from './utils/PresetValidityMessage';
import RequireAgeToBeInRange from './utils/RequireAgeToBeInRange';
import RequiredField from './utils/RequiredField';
import SendFormWithServerSideErrorHandling from './utils/SendFormWithServerSideErrorHandling';
import { RadioSelectionClearButton } from './utils/RadioSelectionClearButton';
import FormRequestController from './utils/FormRequestController';
export var GeschwisterBonusFormFields;
(function (GeschwisterBonusFormFields) {
    GeschwisterBonusFormFields["CHILD_1_BIRTH_DATE"] = "#geschwisterbonus-1208-date-1";
    GeschwisterBonusFormFields["CHILD_2_BIRTH_DATE"] = "#geschwisterbonus-1208-date-2";
    GeschwisterBonusFormFields["CHILD_3_BIRTH_DATE"] = "#geschwisterbonus-1208-date-3";
    GeschwisterBonusFormFields["CHILD_4_BIRTH_DATE"] = "#geschwisterbonus-1208-date-4";
    GeschwisterBonusFormFields["CHILD_5_BIRTH_DATE"] = "#geschwisterbonus-1208-date-5";
    GeschwisterBonusFormFields["CAPTCHA"] = "#geschwisterbonus-1208-captcha-1";
    GeschwisterBonusFormFields["IBAN"] = "#geschwisterbonus-1208-text-27";
})(GeschwisterBonusFormFields || (GeschwisterBonusFormFields = {}));
export default class GeschwisterBonus {
    constructor() {
        this.SELECTOR = 'geschwisterbonus-1208';
        this.features = [
            new RequireAgeToBeInRange(GeschwisterBonusFormFields.CHILD_1_BIRTH_DATE),
            new RequireAgeToBeInRange(GeschwisterBonusFormFields.CHILD_2_BIRTH_DATE),
            new RequireAgeToBeInRange(GeschwisterBonusFormFields.CHILD_3_BIRTH_DATE),
            new RequireAgeToBeInRange(GeschwisterBonusFormFields.CHILD_4_BIRTH_DATE),
            new RequireAgeToBeInRange(GeschwisterBonusFormFields.CHILD_5_BIRTH_DATE),
            new ManageIban(),
            new RequiredField('#geschwisterbonus-1208-singleselect-1', 'Bitte wählen Sie eine Option aus', { placeholder: 'Bitte Schule wählen' }),
            new RequiredField('#geschwisterbonus-1208-singleselect-2', 'Bitte wählen Sie eine Option aus', { placeholder: 'Bitte Schule wählen' }),
            new PresetValidityMessage('#geschwisterbonus-1208-checkbox-2', 'Bitte akzeptieren Sie die Datenschutzbestimmungen'),
            new RadioSelectionClearButton('#geschwisterbonus-1208-radiobutton-2', '#geschwisterbonus-1208-radiobutton-2 .form-radio input', {
                inputsToClearErrorsSelector: GeschwisterBonusFormFields.CAPTCHA,
            }),
            new SendFormWithServerSideErrorHandling(GeschwisterBonusFormFields.CAPTCHA),
        ];
        for (const feature of this.features) {
            // try catch is important here because we don't want a broken feature to impact the others
            try {
                feature.start();
            }
            catch (e) {
                console.error('GeschwisterBonus: Feature failed to start', e);
            }
        }
        const form = document.querySelector(`#${this.SELECTOR}`);
        if (!form)
            throw new Error(`SchulgeldErmaessigung: Form with selector #${this.SELECTOR} not found.`);
        this.form = form;
        form === null || form === void 0 ? void 0 : form.addEventListener('submit', (e) => this.handleSubmit(e));
        this.checkIbanRequired();
    }
    handleSubmit(e) {
        e.preventDefault();
        for (const feature of this.features) {
            try {
                if (!feature.onSubmit)
                    continue;
                const error = feature.onSubmit(e);
                if (error) {
                    e.preventDefault();
                    return;
                }
            }
            catch (e) {
                console.error('GeschwisterBonus: Feature failed to run onSubmit', e);
            }
        }
        // this.form.submit();
    }
    checkIbanRequired() {
        // Select all radio buttons within the specified container
        const radioButtons = document.querySelectorAll('#geschwisterbonus-1208-radiobutton-2 .form-check-input');
        // Function to handle the radio button change event
        const handleRadioChange = () => {
            // Find the input-iban element and set it as required
            const ibanInput = document.querySelector('.input-iban');
            if (ibanInput) {
                ibanInput.setAttribute('required', 'true');
                ManageIban.validateIBAN(ibanInput.value);
                const errorMessage = ManageIban.validateIBAN(ibanInput.value);
                if (errorMessage && ibanInput.value) {
                    FormErrorController.addError(ibanInput, errorMessage);
                }
                else {
                    FormErrorController.removeError(ibanInput);
                }
            }
        };
        // Add change event listeners to each radio button
        for (const radio of radioButtons) {
            radio.addEventListener('change', handleRadioChange);
        }
        const form = document.querySelector(`#${this.SELECTOR}`);
        if (!form)
            throw new Error(`SchulgeldErmaessigung: Form with selector #${this.SELECTOR} not found.`);
        this.form = form;
        new FormRequestController(this);
    }
}
