import FormUtils from './FormUtils';
import { ManageIban } from './utils/ManageIban';
import { PresetValidityMessage } from './utils/PresetValidityMessage';
import RequiredField from './utils/RequiredField';
import ShowErrorsOnSubmit from './utils/ShowErrorsOnSubmit';
import RequireAtLeastOneFile from './utils/RequireAtLeastOneFile';
import FormErrorController from './FormErrorController';
import RequireAgeToBeInRange from './utils/RequireAgeToBeInRange';
import { ShowErrorIfNotOneChecked } from './utils/ShowErrorIfNotOneChecked';
import ScrollUpAtSuccessMessage from './utils/ScrollUpAtSuccessMessage';
import SetRequiredIfCheckboxChecked from './utils/SetRequiredIfCheckboxChecked';
import RequireIBANIfAccountHolderSelected from './utils/RequireIBANIfAccountHolderSelected';
import AddClassOnSubmit from './utils/AddClassOnSubmit';
import EnforceMaxInputTextLength from './utils/EnforceMaxInputTextLength';
import { RadioSelectionClearButton } from './utils/RadioSelectionClearButton';
import SendFormWithServerSideErrorHandling from './utils/SendFormWithServerSideErrorHandling';
import FormRequestController from './utils/FormRequestController';
export var SchulgeldErmaessigungFormFields;
(function (SchulgeldErmaessigungFormFields) {
    SchulgeldErmaessigungFormFields["JOB_CENTER_CHECKBOX"] = "#schulgeldermaessigung-1207-checkbox-1";
    SchulgeldErmaessigungFormFields["JOB_CENTER_FILE_UPLOAD"] = "#schulgeldermaessigung-1207-fileupload-1";
    SchulgeldErmaessigungFormFields["INCOME_PROOF_CHECKBOX"] = "#schulgeldermaessigung-1207-checkbox-2";
    SchulgeldErmaessigungFormFields["PARENT_2_NAME"] = "#schulgeldermaessigung-1207-text-30";
    SchulgeldErmaessigungFormFields["PARENT_2_JOB"] = "#schulgeldermaessigung-1207-text-31";
    SchulgeldErmaessigungFormFields["PARENT_2_EMAIL"] = "#schulgeldermaessigung-1207-email-2";
    SchulgeldErmaessigungFormFields["PARENT_2_PHONE"] = "#schulgeldermaessigung-1207-telephone-1";
    SchulgeldErmaessigungFormFields["CHILD_1_BIRTH_DATE"] = "#schulgeldermaessigung-1207-date-1";
    SchulgeldErmaessigungFormFields["CHILD_2_BIRTH_DATE"] = "#schulgeldermaessigung-1207-date-2";
    SchulgeldErmaessigungFormFields["CHILD_3_BIRTH_DATE"] = "#schulgeldermaessigung-1207-date-3";
    SchulgeldErmaessigungFormFields["CHILD_4_BIRTH_DATE"] = "#schulgeldermaessigung-1207-date-4";
    SchulgeldErmaessigungFormFields["CHILD_5_BIRTH_DATE"] = "#schulgeldermaessigung-1207-date-5";
    SchulgeldErmaessigungFormFields["INCOME_PROOF_NOTHING_APPLIES_CHECKBOX"] = "#schulgeldermaessigung-1207-checkbox-25";
    SchulgeldErmaessigungFormFields["INCOME_PROOF_NOTHING_APPLIES_FILE_UPLOAD"] = "#schulgeldermaessigung-1207-fileupload-2";
    SchulgeldErmaessigungFormFields["INCOME_PROOF_NOTHING_APPLIES_JUSTIFICATION"] = "#schulgeldermaessigung-1207-text-35";
    SchulgeldErmaessigungFormFields["IBAN"] = "#schulgeldermaessigung-1207-text-27";
    SchulgeldErmaessigungFormFields["ACCOUNT_HOLDER_PARENT_2"] = "#schulgeldermaessigung-1207-radiobutton-2-1";
    SchulgeldErmaessigungFormFields["CAPTCHA"] = "#schulgeldermaessigung-1207-captcha-1";
})(SchulgeldErmaessigungFormFields || (SchulgeldErmaessigungFormFields = {}));
export default class SchulgeldErmaessigung {
    constructor() {
        this.SELECTOR = 'schulgeldermaessigung-1207';
        this.features = [
            new RequireAgeToBeInRange(SchulgeldErmaessigungFormFields.CHILD_1_BIRTH_DATE),
            new RequireAgeToBeInRange(SchulgeldErmaessigungFormFields.CHILD_2_BIRTH_DATE),
            new RequireAgeToBeInRange(SchulgeldErmaessigungFormFields.CHILD_3_BIRTH_DATE),
            new RequireAgeToBeInRange(SchulgeldErmaessigungFormFields.CHILD_4_BIRTH_DATE),
            new RequireAgeToBeInRange(SchulgeldErmaessigungFormFields.CHILD_5_BIRTH_DATE),
            new ShowErrorIfNotOneChecked([
                SchulgeldErmaessigungFormFields.INCOME_PROOF_CHECKBOX,
                SchulgeldErmaessigungFormFields.JOB_CENTER_CHECKBOX
            ]),
            new SetRequiredIfCheckboxChecked(SchulgeldErmaessigungFormFields.JOB_CENTER_FILE_UPLOAD, SchulgeldErmaessigungFormFields.JOB_CENTER_CHECKBOX),
            new SetRequiredIfCheckboxChecked(SchulgeldErmaessigungFormFields.INCOME_PROOF_NOTHING_APPLIES_FILE_UPLOAD, SchulgeldErmaessigungFormFields.INCOME_PROOF_NOTHING_APPLIES_CHECKBOX),
            new RequireAtLeastOneFile(),
            new ManageIban(),
            new RequiredField('#schulgeldermaessigung-1207-singleselect-1', 'Bitte wählen Sie eine Option aus', { placeholder: 'Bitte Schule wählen' }),
            new PresetValidityMessage('#schulgeldermaessigung-1207-checkbox-24', 'Bitte akzeptieren Sie die Datenschutzbestimmungen'),
            new ShowErrorsOnSubmit(),
            new ScrollUpAtSuccessMessage('#schulgeldermaessigung-1207'),
            new RequireIBANIfAccountHolderSelected(SchulgeldErmaessigungFormFields.IBAN, [
                '#schulgeldermaessigung-1207-radiobutton-2-0',
                '#schulgeldermaessigung-1207-radiobutton-2-1'
            ]),
            new SetRequiredIfCheckboxChecked(SchulgeldErmaessigungFormFields.PARENT_2_NAME, SchulgeldErmaessigungFormFields.ACCOUNT_HOLDER_PARENT_2),
            new SetRequiredIfCheckboxChecked(SchulgeldErmaessigungFormFields.PARENT_2_JOB, SchulgeldErmaessigungFormFields.ACCOUNT_HOLDER_PARENT_2),
            new SetRequiredIfCheckboxChecked(SchulgeldErmaessigungFormFields.PARENT_2_EMAIL, SchulgeldErmaessigungFormFields.ACCOUNT_HOLDER_PARENT_2),
            new SetRequiredIfCheckboxChecked(SchulgeldErmaessigungFormFields.PARENT_2_PHONE, SchulgeldErmaessigungFormFields.ACCOUNT_HOLDER_PARENT_2),
            new AddClassOnSubmit('#schulgeldermaessigung-1207', 'form--submitted'),
            new EnforceMaxInputTextLength(SchulgeldErmaessigungFormFields.INCOME_PROOF_NOTHING_APPLIES_JUSTIFICATION, 120),
            new RadioSelectionClearButton('#schulgeldermaessigung-1207-radiobutton-2', '#schulgeldermaessigung-1207-radiobutton-2 .form-radio input', {
                inputsToClearErrorsSelector: SchulgeldErmaessigungFormFields.IBAN
            }),
            new SendFormWithServerSideErrorHandling(SchulgeldErmaessigungFormFields.CAPTCHA),
        ];
        for (const feature of this.features) {
            try {
                if (feature.start) {
                    feature.start();
                }
            }
            catch (e) {
                console.error('SchulgeldErmaessigung: Feature failed to start', e);
            }
        }
        const allInputs = document.querySelectorAll('input,select,textarea');
        const allButtons = document.querySelectorAll('button');
        for (const input of allInputs) {
            input.addEventListener('change', () => {
                FormErrorController.update({ focusList: false });
            });
        }
        for (const button of allButtons) {
            button.addEventListener('click', () => {
                if (button.type === 'submit')
                    return;
                FormErrorController.update({ focusList: false });
            });
        }
        FormUtils.turnOffAutocomplete(['#schulgeldermaessigung-1207-text-7']);
        FormUtils.prefillField('#schulgeldermaessigung-1207-text-27', 'DE');
        const form = document.querySelector(`#${this.SELECTOR}`);
        if (!form)
            throw new Error(`SchulgeldErmaessigung: Form with selector #${this.SELECTOR} not found.`);
        this.form = form;
        new FormRequestController(this);
    }
}
