/**
 * Simple Selector Function
 */
export const select = (el, all = false) => {
    var _a;
    if (!el)
        return;
    if (typeof el === 'string') {
        el = el.trim();
    }
    else if (typeof el === 'object') {
        el = ((_a = el.textContent) === null || _a === void 0 ? void 0 : _a.trim()) || '';
    }
    if (all) {
        return [...document.querySelectorAll(el)];
    }
    else {
        return [document.querySelector(el)];
    }
};
/**
 * Easy event listener function
 */
export const on = (type, selector, listener, all = false) => {
    var _a;
    const selectEl = select(selector, all);
    if (selectEl) {
        if (all) {
            selectEl.forEach((ele) => ele.addEventListener(type, listener));
        }
        else {
            (_a = selectEl[0]) === null || _a === void 0 ? void 0 : _a.addEventListener(type, listener);
        }
    }
};
