var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { select } from '../utils';
/**
 * Select all elements with these classes.
 */
const MODAL_CLASSES = '[data-modal="true"]';
/**
 * @author Manuel Sanchez <manuel@webschuppen.com>, Michael Peters <michael@webschuppen.com>
 * @version 1.0.1
 *
 * @description
 * Opens element in modal.
 * Selects all elements with the attribute data-modal="true".
 * Additionally, if there is a text, it truncates the text of the card.
 *
 * @example
 * initAnchorToModal();
 */
const createModalOverlay = () => {
    const overlay = document.createElement('div');
    overlay.classList.add('overlay');
    document.body.appendChild(overlay);
    return overlay;
};
const createModalCard = () => {
    const modalCard = document.createElement('div');
    modalCard.classList.add('modal');
    modalCard.classList.add('card__modal');
    modalCard.innerHTML = `
  <button aria-label="Modal zumachen" class="btn-close modal__close">X</button>
  <div class="modal-content"></div>
    `;
    document.body.appendChild(modalCard);
    return modalCard;
};
export const initAnchorToModal = () => {
    let overlay = document.querySelector('.overlay') || createModalOverlay();
    const anchorElements = select(MODAL_CLASSES, true);
    const modal = createModalCard();
    const cardModalBody = modal === null || modal === void 0 ? void 0 : modal.querySelector('.modal-content');
    const cardModalClose = modal === null || modal === void 0 ? void 0 : modal.querySelector('.modal__close');
    anchorElements.forEach((anchor) => {
        anchor === null || anchor === void 0 ? void 0 : anchor.addEventListener('click', (event) => {
            const link = addWordToLinkIfMissing(anchor === null || anchor === void 0 ? void 0 : anchor.getAttribute('href'), 'ajax');
            event.preventDefault();
            overlay === null || overlay === void 0 ? void 0 : overlay.classList.add('active');
            modal === null || modal === void 0 ? void 0 : modal.classList.add('active');
            document.body.classList.add('overflow-hidden');
            cardModalBody.innerHTML = '';
            fetchHTMLContent(link).then((html) => {
                cardModalBody.innerHTML = html;
            });
        });
    });
    document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
            overlay === null || overlay === void 0 ? void 0 : overlay.classList.remove('active');
            modal === null || modal === void 0 ? void 0 : modal.classList.remove('active');
            document.body.classList.remove('overflow-hidden');
        }
    });
    cardModalClose === null || cardModalClose === void 0 ? void 0 : cardModalClose.addEventListener('click', () => {
        overlay === null || overlay === void 0 ? void 0 : overlay.classList.remove('active');
        modal === null || modal === void 0 ? void 0 : modal.classList.remove('active');
        document.body.classList.remove('overflow-hidden');
    });
    overlay === null || overlay === void 0 ? void 0 : overlay.addEventListener('click', () => {
        overlay === null || overlay === void 0 ? void 0 : overlay.classList.remove('active');
        modal === null || modal === void 0 ? void 0 : modal.classList.remove('active');
        document.body.classList.remove('overflow-hidden');
    });
};
const fetchHTMLContent = (url) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetch(url);
    const html = response.text();
    return html;
});
const addWordToLinkIfMissing = (link, wordToAdd) => {
    if (link.includes(wordToAdd))
        return link;
    let [firstHalf, secondHalf] = link.split('?');
    if (!firstHalf.endsWith('/'))
        firstHalf += '/';
    firstHalf += wordToAdd;
    const editedLink = [firstHalf, secondHalf].join('?');
    return editedLink;
};
